<template>
  <div id="auth-link-layout">
    <v-app-bar app color="white" flat height="90">
      <v-img
        class="home-logo"
        :src="require('@/assets/logo.svg')"
        height="36px"
        contain
      />
    </v-app-bar>

    <div v-if="init && !isLoading" class="main-content">
      <div v-if="auth.passcodetoken == null || auth.passcodetoken == ''">
        <h2>
          จัดการข้อมูลสำเร็จ!
          <br />
          ขอบคุณที่มาใช้บริการที่ เดอะ สตรีท รัชดา
        </h2>
      </div>
      <div v-else-if="code != null" class="customer-profile-container">
        <v-form
          :disabled="isLoading"
          v-model="valid"
          @submit.prevent="submitForm"
        >
          <h1 class="mb-4 px-3">
            ข้อมูลลูกค้า / Customer Information ({{ code }})
          </h1>
          <v-row class="text-field">
            <v-col class="py-0" cols="12" sm="2">
              <div>คำนำหน้า / Title<span class="red--text">*</span></div>
              <v-combobox
                v-model="comboBoxTitle"
                :items="titleItems"
                placeholder="คำนำหน้า / Title"
                item-value="value"
                item-text="name"
                outlined
                dense
                color="black"
                background-color="white"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col class="py-0" cols="12" sm="3">
              <div>ชื่อ / Name<span class="red--text">*</span></div>
              <v-text-field
                placeholder="ชื่อ / Name"
                outlined
                dense
                color="black"
                background-color="white"
                v-model="formData.firstName"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col class="py-0" cols="12" sm="3">
              <div>นามสกุล / Last Name<span class="red--text">*</span></div>
              <v-text-field
                placeholder="นามสกุล  / Last Name"
                outlined
                dense
                color="black"
                background-color="white"
                v-model="formData.lastName"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col class="py-0" cols="12" sm="4">
              <div>
                เบอร์โทรศัพท์ / Mobile Phone No.<span class="red--text">*</span>
              </div>
              <v-text-field
                placeholder="เบอร์โทรศัพท์ / Mobile Phone No."
                outlined
                dense
                color="black"
                background-color="white"
                v-model="formData.phone"
                :rules="[rules.required, rules.max]"
                @keypress="validateNumeric"
              />
            </v-col>
          </v-row>
          <v-row class="text-field">
            <v-col class="py-0" cols="12" sm="5">
              <div>อีเมล / E-mail Address</div>
              <v-text-field
                placeholder="อีเมล / E-mail Address"
                outlined
                dense
                color="black"
                background-color="white"
                v-model="formData.email"
                :rules="[rules.emailValidate]"
              />
            </v-col>
            <v-col class="py-0" cols="12" sm="7">
              <div>เลขบัตรประชาชน หรือ พาสปอร์ต / ID Card No. or Passport</div>
              <v-text-field
                placeholder="เลขบัตรประชาชน หรือ พาสปอร์ต / ID Card No. or Passport"
                outlined
                dense
                color="black"
                background-color="white"
                v-model="formData.citizenId"
                :rules="[rules.citizenValidate]"
              />
            </v-col>
          </v-row>
          <v-row class="text-field">
            <v-col class="py-0" cols="12" md="4">
              <div>
                วันเดือนปีเกิด / Date of Birth<span class="red--text">*</span>
              </div>
              <BirthDateInput
                placeholder="วันเดือนปีเกิด / Date of Birth"
                format="DD/MM/YYYY"
                persistentPlaceholder
                :value="formData.birthDate"
                @input="updateDateInput"
                @clear="onClear"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col class="py-0" cols="12" sm="4">
              <div>ประเทศ / Country</div>
              <v-combobox
                v-model="formData.country"
                :items="countryItems"
                persistent-placeholder
                placeholder="ประเทศ / Country"
                item-value="id"
                item-text="name"
                outlined
                dense
                color="black"
                background-color="white"
              />
            </v-col>
            <v-col class="py-0" cols="12" sm="4">
              <div>ทะเบียนรถ / License Plate No.</div>
              <v-text-field
                placeholder="ทะเบียนรถ / License Plate No."
                outlined
                dense
                color="black"
                background-color="white"
                v-model="formData.vehicleRegistrationNo"
              />
            </v-col>
          </v-row>
          <v-row class="text-field">
            <v-col class="py-0" cols="12" sm="6">
              <div>เพศ / Gender</div>
              <v-radio-group v-model="formData.gender" class="mt-0" row>
                <v-radio label="ชาย / Men" value="men" />
                <v-radio label="หญิง / Women" value="women" />
              </v-radio-group>
            </v-col>
            <v-col class="py-0" cols="12" sm="6">
              <div>สถานภาพ / Marital Status</div>
              <v-radio-group v-model="formData.maritalStatus" class="mt-0" row>
                <v-radio label="โสด / Single" :value="false" />
                <v-radio label="สมรส / Married" :value="true" />
              </v-radio-group>
            </v-col>
          </v-row>

          <h1 class="px-3">ที่อยู่ลูกค้า / Address</h1>
          <div class="mb-4 px-3">ที่อยู่ปัจจุบัน / Current Address</div>
          <v-row class="text-field">
            <v-col class="py-0" cols="12" sm="4">
              <div>บ้านเลขที่ / House No.</div>
              <v-text-field
                placeholder="บ้านเลขที่ / House No."
                outlined
                dense
                color="black"
                background-color="white"
                v-model="formAddress.houseNo"
              />
            </v-col>
            <v-col class="py-0" cols="12" sm="4">
              <div>ซอย / Lane</div>
              <v-text-field
                placeholder="ซอย / Lane"
                outlined
                dense
                color="black"
                background-color="white"
                v-model="formAddress.lane"
              />
            </v-col>
            <v-col class="py-0" cols="12" sm="4">
              <div>หมู่ / Village No.</div>
              <v-text-field
                placeholder="หมู่ / Village No."
                outlined
                dense
                color="black"
                background-color="white"
                v-model="formAddress.villageNo"
              />
            </v-col>
          </v-row>
          <v-row class="text-field">
            <v-col class="py-0" cols="12" sm="6">
              <div>ถนน / Road</div>
              <v-text-field
                placeholder="ถนน / Road"
                outlined
                dense
                color="black"
                background-color="white"
                v-model="formAddress.road"
              />
            </v-col>
            <v-col class="py-0" cols="12" sm="6">
              <div>หมู่บ้าน/อาคาร / Village/Building</div>
              <v-text-field
                placeholder="หมู่บ้าน/อาคาร / Village/Building"
                outlined
                dense
                color="black"
                background-color="white"
                v-model="formAddress.village"
              />
            </v-col>
          </v-row>
          <v-row class="text-field">
            <v-col class="py-0" cols="12" sm="3">
              <div>
                รหัสไปรษณีย์ / Postal Code<span class="red--text">*</span>
              </div>
              <v-autocomplete
                v-model="formAddress.postcode"
                :items="postalCodeItems"
                persistent-placeholder
                placeholder="00000"
                :item-value="
                  (item) => {
                    return item
                  }
                "
                :item-text="
                  (item) => {
                    return `${item.postcode} ${item.subDistrict} ${item.district} ${item.province}`
                  }
                "
                outlined
                dense
                color="black"
                background-color="white"
                @keypress="validateNumeric"
                @change="inputPostalCode"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col class="py-0" cols="12" sm="3">
              <div>จังหวัด / Province<span class="red--text">*</span></div>
              <v-autocomplete
                v-model="formAddress.province"
                :items="provinceItems"
                persistent-placeholder
                placeholder="กรุณาเลือก"
                item-value="id"
                item-text="name"
                outlined
                dense
                color="black"
                background-color="white"
                @change="inputProvince"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col class="py-0" cols="12" sm="3">
              <div>อำเภอ / เขต / District<span class="red--text">*</span></div>
              <v-autocomplete
                v-model="formAddress.district"
                :items="districtItems"
                persistent-placeholder
                placeholder="กรุณาเลือก"
                item-value="id"
                item-text="name"
                outlined
                dense
                color="black"
                background-color="white"
                @change="inputDistrict"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col class="py-0" cols="12" sm="3">
              <div>
                ตำบล / แขวง / Sub District<span class="red--text">*</span>
              </div>
              <v-autocomplete
                v-model="formAddress.subDistrict"
                :items="subDistrictItems"
                persistent-placeholder
                placeholder="กรุณาเลือก"
                item-value="id"
                item-text="name"
                outlined
                dense
                color="black"
                background-color="white"
                @change="inputSubDistrict"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-row class="text-field">
            <v-col class="py-0" cols="12" sm="12">
              <div>การศึกษา</div>
              <v-radio-group v-model="formData.education" class="mt-0" row>
                <v-radio label="ประถมศึกษา" value="primary" />
                <v-radio label="มัธยมศึกษา" value="high school" />
                <v-radio label="ปริญญาตรี" value="bachelor" />
                <v-radio label="สูงกว่าปริญญาตรี" value="higher bachelor" />
                <v-radio label="อื่นๆ" value="other" />
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row class="text-field">
            <v-col class="py-0" cols="12" sm="12">
              <div>รายได้ต่อครัวเรือน / เดือน</div>
              <v-radio-group v-model="formData.incomeRate" class="mt-0" row>
                <v-radio label="ต่ำกว่า 15,000 บาท" value="<15000" />
                <v-radio label="15,000 - 30,000 บาท" value="15000-30000" />
                <v-radio label="30,001 - 50,000 บาท" value="30001-50000" />
                <v-radio label="50,001 - 70,000 บาท" value="50001-70000" />
                <v-radio label="70,001 - 100,000 บาท" value="70001-100000" />
                <v-radio label="100,000 บาทขึ้นไป" value=">100000" />
              </v-radio-group>
            </v-col>
          </v-row>

          <v-divider class="my-1 mx-3" />
          <v-row class="mx-1">
            <v-col cols="12" sm="4" md="3" lg="2">
              <v-btn
                class="mt-4"
                color="#f7941e"
                type="submit"
                block
                :disabled="validate || !valid"
              >
                บันทึก
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
    <!-- <Alert
      :snackbar="snackbar"
      :type="alertType"
      :text="alertText"
      :onClose="() => onCloseAlert()"
    /> -->
    <div v-show="!init || isLoading" class="loading-process">
      <v-progress-circular indeterminate color="white" />
    </div>
  </div>
</template>

<script>
import { isEmpty, size } from 'lodash'

import BirthDateInput from '@/components/BirthDateInput'

import { SetRedirectURL } from '@/plugins/common'

import { REQUEST_GET } from '@/store/API_Request'

import Utils from '@/mixin'

export default {
  mixins: [Utils],
  components: {
    BirthDateInput,
  },
  data: () => ({
    valid: false,
    formData: {
      code: '',
      title: '',
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      citizenId: '',
      birthDate: null,
      country: null,
      vehicleRegistrationNo: '',
      gender: '',
      education: '',
      incomeRate: '',
    },
    formAddress: {
      type: 'current',
      houseNo: '',
      lane: '',
      village: '',
      villageNo: '',
      road: '',
      subDistrictId: null,
      districtId: null,
      provinceId: null,
      postcode: '',
    },
    countryItems: [],
    provinceItems: [],
    districtItems: [],
    subDistrictItems: [],
    postalCodeItems: [],
    titleItems: [
      { value: 'mr', name: 'นาย' },
      { value: 'ms', name: 'นาง' },
      { value: 'mrs', name: 'นางสาว' },
    ],
    init: false,
    rules: {
      required: (value) => !!value || 'Require field.',
      emailValidate: (email) => {
        const re =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ //eslint-disable-line
        if (isEmpty(email) || re.test(String(email).toLowerCase())) {
          return true
        }
        return 'Invalid format.'
      },
      max: (value) => (!!value && value.length === 10) || 'Invalid format.',
      citizenValidate: (value) => {
        const numberValidate = /^[0-9]+$/ //eslint-disable-line
        if (
          value === '' ||
          (numberValidate.test(value) && value.length === 13)
        ) {
          return true
        }
        return 'Invalid format.'
        // if (numberValidate.test(value)) {
        //   return (!!value && value.length === 13) || 'Invalid format.'
        // } else {
        //   return (!!value && value.length <= 13) || 'Invalid format.'
        // }
      },
    },
  }),
  computed: {
    validate() {
      const { title, firstName, lastName, birthDate, phone } = this.formData
      const { district, province, postcode } = this.formAddress
      if (
        !(
          title &&
          firstName &&
          lastName &&
          birthDate &&
          phone &&
          postcode &&
          district?.id &&
          province?.id
        )
      )
        return true
      return false
    },
    code() {
      if (this.customerProfile?.id == null) return null
      return ('00000' + this.customerProfile?.id).slice(-6)
    },
    comboBoxTitle: {
      get() {
        const index = this.titleItems.findIndex(
          (obj) => obj.value === this.formData.title
        )
        if (index >= 0) {
          return this.titleItems[index].name
        }

        return ''
      },
      set(val) {
        this.formData.title = val.value
      },
    },
  },
  created() {
    localStorage.removeItem('token')
  },
  async mounted() {
    await this.checkHash()

    if (this.auth.passcodetoken != '') {
      await this.getInitialData()
    }
  },
  methods: {
    async submitForm() {
      const { formData, formAddress } = this
      let urlParams = new URLSearchParams(window.location.search)
      let submitForm = {
        module: 'register',
        ref: urlParams.get('ref'),
        code: urlParams.get('code'),
        id: parseInt(formData.id),
        title: formData.title,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        phone: formData.phone,
        birthDate: formData.birthDate,
        citizenId: formData.citizenId,
        gender: formData.gender,
        vehicleRegistrationNo: formData.vehicleRegistrationNo,
        countryId: formData.country?.id,
        education: formData.education,
        incomeRate: formData.incomeRate,
        maritalStatus: formData.maritalStatus,
        address: {
          type: 'current',
          houseNo: formAddress.houseNo,
          lane: formAddress.lane,
          village: formAddress.village,
          villageNo: formAddress.villageNo,
          road: formAddress.road,
          subDistrictId: formAddress.subDistrict?.id,
          districtId: formAddress.district?.id,
          provinceId: formAddress.province?.id,
          postcode: formAddress.postcode?.postcode,
        },
      }

      const res = await this.updateCustomerProfileByRefcode(submitForm)
      let text = ''
      switch (res) {
        case 'Duplicate email':
          text = 'อีเมล์นี้เป็นสมาชิก The Street Point ในระบบแล้ว'
          break
        case 'Duplicate phone number':
          text = 'หมายเลขนี้เป็นสมาชิก The Street Point ในระบบแล้ว'
          break
        case 'Invalid data':
          text = 'กรุณากรอกข้อมูลให้ถูกต้อง'
          break
        default:
          break
      }
      if (text !== '') {
        this.conf = {
          ...this.conf,
          status: true,
          title: 'แจ้งเตือน!',
          text,
        }
      } else {
        location.reload()
      }
    },
    validateNumeric(evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (charCode >= 48 && charCode <= 57) {
        return true
      } else {
        evt.preventDefault()
      }
    },
    updateDateInput(e) {
      this.formData.birthDate = e
    },
    onClear() {
      this.formData.birthDate = null
    },
    async checkHash() {
      // replace url pathname
      const { origin, pathname, search } = window.location
      const aLink = document.createElement('a')
      aLink.href = origin + pathname + search
      const url = new URL(aLink)

      const redirect = SetRedirectURL(
        this.$route.hash,
        '/register' + encodeURIComponent(search)
      )
      if (redirect.token) {
        this.access_token = redirect.token
        // replace url pathname
        let urlParams = new URLSearchParams(window.location.search)
        const formData = {
          module: 'register',
          ref: urlParams.get('ref'),
          code: urlParams.get('code'),
        }
        await this.authWithRefCode({ code: redirect.token, formData })
        window.history.pushState({}, '', url)

        this.init = true
      } else {
        window.location = redirect.url
      }
    },
    async getInitialData() {
      await this.toggleLoading()
      await this.getMyProfile()
      await this.getCountry()
      await this.getProvince()
      await this.getDistrict({})
      await this.getSubDistrict({})
      await this.getPostcode({})

      const { addresses, ...profile } = this.customerProfile
      this.formData = Object.assign({}, this.formData, profile)
      if (size(addresses) > 0) {
        const { district, province, subDistrict, postcode } = addresses?.[0]
        this.formAddress = Object.assign({}, this.formAddress, {
          ...addresses?.[0],
          postcode: {
            postcode,
            subDistrictId: subDistrict?.id,
            subDistrict: subDistrict?.name,
            districtId: district?.id,
            district: district?.name,
            provinceId: province?.id,
            province: province?.name,
          },
        })
      }
      await this.toggleLoading()
    },
    async getCountry() {
      try {
        const res = await REQUEST_GET('/api/countries?listType=all')
        this.countryItems = res.data.data
      } catch (e) {
        console.log(e)
      }
    },
    async getProvince() {
      try {
        const res = await REQUEST_GET('/api/provinces?listType=all')
        this.provinceItems = res.data.data
      } catch (e) {
        console.log(e)
      }
    },
    async getDistrict({ provinceId = null }) {
      try {
        const res = await REQUEST_GET(
          `/api/districts?listType=all${
            provinceId !== null ? `&provinceId=${provinceId}` : ''
          }`
        )
        this.districtItems = res.data.data
      } catch (e) {
        console.log(e)
      }
    },
    async getSubDistrict({ provinceId = null, districtId = null }) {
      try {
        const res = await REQUEST_GET(
          `/api/sub-districts?listType=all${
            provinceId !== null ? `&provinceId=${provinceId}` : ''
          }${districtId !== null ? `&districtId=${districtId}` : ''}`
        )
        this.subDistrictItems = res.data.data
      } catch (e) {
        console.log(e)
      }
    },
    async getPostcode({
      provinceId = null,
      districtId = null,
      subDistrictId = null,
      query = null,
    }) {
      try {
        const res = await REQUEST_GET(
          `/api/postcodes?listType=all${
            provinceId !== null ? `&provinceId=${provinceId}` : ''
          }${districtId !== null ? `&districtId=${districtId}` : ''}${
            subDistrictId !== null ? `&subDistrictId=${subDistrictId}` : ''
          }${query !== null ? `&query=${query}` : ''}`
        )
        this.postalCodeItems = res.data.data
      } catch (e) {
        console.log(e)
      }
    },
    async inputProvince() {
      const { formAddress } = this
      await this.getPostcode({ provinceId: formAddress?.province })
      await this.getDistrict({ provinceId: formAddress?.province })
      await this.getSubDistrict({ provinceId: formAddress?.province })
      this.formAddress = Object.assign({}, this.formAddress, {
        district: '',
        subDistrict: '',
        postcode: '',
      })
    },
    async inputDistrict() {
      const { formAddress } = this
      await this.getPostcode({
        provinceId: formAddress?.province,
        districtId: formAddress?.district,
      })
      await this.getSubDistrict({
        provinceId: formAddress?.province,
        districtId: formAddress?.district,
      })
      this.formAddress = Object.assign({}, this.formAddress, {
        subDistrict: '',
        postcode: '',
      })
    },
    async inputSubDistrict() {
      const { formAddress } = this
      await this.getPostcode({
        provinceId: formAddress?.province,
        districtId: formAddress?.district,
        subDistrictId: formAddress?.subDistrict,
      })
      this.formAddress = Object.assign({}, this.formAddress, {
        postcode: '',
      })
    },
    async inputPostalCode() {
      const { postcode } = this.formAddress
      this.formAddress = Object.assign({}, this.formAddress, {
        province: { name: postcode.province, id: postcode.provinceId },
        district: { name: postcode.district, id: postcode.districtId },
        subDistrict: { name: postcode.subDistrict, id: postcode.subDistrictId },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
$orange: #f7941e;

.branch-button {
  border-radius: 4px !important;
  color: rgba(0, 0, 0, 0.87) !important;
  letter-spacing: 0 !important;
  font-size: 0.8125rem !important;
  padding: 2px 8px !important;
  span {
    justify-content: flex-start;
  }
}

.customer-profile-container {
  flex: 1;

  h1 {
    white-space: normal;
  }
}

#auth-link-layout {
  min-height: 100vh;
  position: relative;
  .main-content {
    display: flex;
    justify-content: center;
  }
  .v-list {
    .v-list-item--active {
      &::before {
        background: $orange;
        opacity: 1;
      }
      .v-list-item__content {
        z-index: 1;
      }
    }
  }
  .loading-process {
    z-index: 999;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.3);
    > div {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
