import { get, isEmpty } from 'lodash'

import { setAuthorization, REQUEST_GET, REQUEST_POST } from './API_Request'
import { getToken, redirectLogin } from '@/plugins/auth'
import { application, AlertMessage, errorMessage } from '@/plugins/common'
import {
  GLOBAL_MESSAGE,
  LOGIN_MESSAGE,
  FORGOT_PASSWORD_MESSAGE,
  RESET_PASSWORD_MESSAGE,
} from '@/plugins/message'

export const state = {
  token: '',
  apptoken: '',
  passcodetoken: '',
  access_count: 0,
}

const sleep = (ms = 1000) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, ms)
  })
}

export const mutations = {
  async APP_ACCESS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET('/api/access_token', {
        application,
        code: data.code,
      })
      const token = {
        accessToken: get(res, 'data.access_token'),
        tokenType: get(res, 'data.token_type'),
        expiresIn: get(res, 'data.expires_in'),
      }

      state.apptoken = token.accessToken
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      if (state.access_count >= 2) {
        localStorage.setItem('access_count', 0)
        const error = errorMessage(e)
        const alert = new AlertMessage(error, 'error', 'error')
        data.toggleAlert(alert)

        await sleep(1000)
        window.location = '/login'
      } else {
        state.access_count++

        await sleep(2000)
        return mutations.APP_ACCESS(state, data)
      }
      return e
    }
  },
  async LOGIN(state, data) {
    data.toggleLoading()
    try {
      const headers = {
        Authorization: state.apptoken && `Bearer ${state.apptoken}`,
      }
      const form = {
        channel: 'password',
        credential: data.formData,
      }

      const res = await REQUEST_POST('/api/auth', form, { headers })
      const token = {
        accessToken: get(res, 'data.access_token'),
        tokenType: get(res, 'data.token_type'),
        expiresIn: get(res, 'data.expires_in'),
      }

      state.token = token.accessToken
      localStorage.setItem('token', token.accessToken)
      sessionStorage.setItem('token', token.accessToken)
      data.toggleLoading()

      switch (get(res, 'status')) {
        case 200: {
          const alert = new AlertMessage(LOGIN_MESSAGE.SUCCESS, 'ok', 'success')
          data.toggleAlert(alert)
          setAuthorization(state.token)
          redirectLogin()
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      if (error) alert = new AlertMessage(LOGIN_MESSAGE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return e
    }
  },
  async LOGINWITHREFCODE(state, data) {
    data.toggleLoading()
    try {
      localStorage.removeItem('token')
      const res_access = await REQUEST_GET('/api/access_token', {
        application,
        code: data.code,
      })
      state.apptoken =  get(res_access, 'data.access_token')
      
      const headers = {
        Authorization: state.apptoken && `Bearer ${state.apptoken}`,
      }

      const form = {
        channel: 'refcode',
        credential: data.formData,
      }

      const res = await REQUEST_POST('/api/auth', form, { headers })
      const token = {
        accessToken: get(res, 'data.access_token'),
        tokenType: get(res, 'data.token_type'),
        expiresIn: get(res, 'data.expires_in'),
      }

      state.passcodetoken = token.accessToken
      localStorage.setItem('token', token.accessToken)
      sessionStorage.setItem('token', token.accessToken)
      data.toggleLoading()

      switch (get(res, 'status')) {
        case 200: {
          data.toggleAlert(alert)
          setAuthorization(state.passcodetoken)
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      return e
    }
  },
  LOGOUT() {
    localStorage.removeItem('token')
    sessionStorage.removeItem('token')
    location.reload()
  },
  async FORGOT_PASSWORD(state, data) {
    data.toggleLoading()
    try {
      const headers = {
        Authorization: state.apptoken && `Bearer ${state.apptoken}`,
      }
      const res = await REQUEST_POST('/api/forgot-password', data.formData, {
        headers,
      })
      data.toggleLoading()
      switch (get(res, 'status')) {
        case 200: {
          const alert = new AlertMessage(
            FORGOT_PASSWORD_MESSAGE.SUCCESS,
            'ok',
            'success'
          )
          data.toggleAlert(alert)
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      if (error)
        alert = new AlertMessage(
          FORGOT_PASSWORD_MESSAGE.ERROR,
          'error',
          'error'
        )
      data.toggleAlert(alert)
      return e
    }
  },
  async RESET_PASSWORD(state, data) {
    data.toggleLoading()
    try {
      const headers = {
        Authorization: state.apptoken && `Bearer ${state.apptoken}`,
      }
      const res = await REQUEST_POST('/api/reset-password', data.formData, {
        headers,
      })
      data.toggleLoading()
      switch (get(res, 'status')) {
        case 200: {
          const alert = new AlertMessage(
            RESET_PASSWORD_MESSAGE.SUCCESS,
            'ok',
            'success'
          )
          data.toggleAlert(alert)

          await sleep(1000)
          window.location = '/login'
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      if (error)
        alert = new AlertMessage(RESET_PASSWORD_MESSAGE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return e
    }
  },
  async GET_MKT_CONSENT(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(`/api/mktconsents`)
      data.toggleLoading()
      return res
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      const alert = new AlertMessage(error, 'error', 'error')
      data.toggleAlert(alert)
      return e
    }
  },
  async CHECK_BY_USER_MKT_CONSENT(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(
        `/api/users/${data?.user?.userId}/mktconsents`
      )
      data.toggleLoading()
      return res
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      const alert = new AlertMessage(error, 'error', 'error')
      data.toggleAlert(alert)
      return e
    }
  },
  async GET_USER_CONSENT(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(`/api/consents`)
      data.toggleLoading()
      return res
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      const alert = new AlertMessage(error, 'error', 'error')
      data.toggleAlert(alert)
      return e
    }
  },
  async CHECK_USER_CONSENT(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(
        `/api/users/${data?.user?.userId}/consents/waiting`
      )
      data.toggleLoading()
      return res
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      const alert = new AlertMessage(error, 'error', 'error')
      data.toggleAlert(alert)
      return e
    }
  },
  async ACCEPT_USER_CONSENT(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_POST(`/api/consents`, { data: data.form })
      switch (get(res, 'status')) {
        case 200: {
          const alert = new AlertMessage(
            GLOBAL_MESSAGE.UPDATE.SUCCESS,
            'ok',
            'success'
          )
          data.toggleAlert(alert)
          data.toggleLoading()
          return state
        }
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      const alert = new AlertMessage(error, 'error', 'error')
      data.toggleAlert(alert)
      return e
    }
  },
}

export const actions = {
  appAccess(context, code) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.APP_ACCESS(state, { toggleLoading, code, toggleAlert })
  },
  login(context, formData) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    context.commit('LOGIN', { toggleLoading, formData, toggleAlert })
  },
  authWithRefCode(context, data) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.LOGINWITHREFCODE(state, { toggleLoading, ...data, toggleAlert })
  },
  logout(context) {
    context.commit('LOGOUT')
  },
  forgotPassword(context, formData) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    context.commit('FORGOT_PASSWORD', { toggleLoading, formData, toggleAlert })
  },
  resetPassword(context, formData) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    context.commit('RESET_PASSWORD', { toggleLoading, formData, toggleAlert })
  },
  getMktConsent(context) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_MKT_CONSENT(state, {
      toggleLoading,
      toggleAlert,
    })
  },
  checkByUserMktConsent(context, user) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.CHECK_BY_USER_MKT_CONSENT(state, {
      toggleLoading,
      user,
      toggleAlert,
    })
  },
  getUserConsent(context) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_USER_CONSENT(state, {
      toggleLoading,
      toggleAlert,
    })
  },
  checkUserConsent(context, user) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.CHECK_USER_CONSENT(state, {
      toggleLoading,
      user,
      toggleAlert,
    })
  },
  acceptUserConsent(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.ACCEPT_USER_CONSENT(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
}

export const getters = {
  // Add Logic Before Computed
  auth(state) {
    const token = getToken()
    if (!isEmpty(token)) state.token = token
    return state
  },
}

export const authentication = {
  state,
  mutations,
  actions,
  getters,
}
