import { cloneDeep, findIndex } from 'lodash'
import {
  REQUEST_GET,
  REQUEST_POST,
  REQUEST_PUT,
  REQUEST_DELETE,
} from './API_Request'
import { AlertMessage, errorMessage } from '@/plugins/common'
import { GLOBAL_MESSAGE } from '@/plugins/message'

export const state = {
  promotions: {
    data: [],
    total: 0,
  },
}

export const mutations = {
  async GET_GLOBAL_PROMOTIONS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET('/api/global-point-promotions', data.form)
      state.promotions.data = res?.data?.data || []
      state.promotions.total = res?.data?.total || 0
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_SHOP_PROMOTIONS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(
        `/api/shops/${data?.form?.shopId}/point-promotions`,
        data.form
      )
      state.promotions.data = res?.data?.data || []
      state.promotions.total = res?.data?.total || 0
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async CREATE_PROMOTIONS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_POST('/api/point-promotions', data.form)
      data.toggleLoading()
      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.CREATE.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)

      return res
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.CREATE.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
  async EDIT_PROMOTIONS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_PUT(
        `/api/point-promotions/${data.form?.id}`,
        data.form
      )
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.EDIT.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return res
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.EDIT.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
  async DELETE_PROMOTIONS(state, data) {
    data.toggleLoading()
    try {
      await REQUEST_DELETE(`/api/point-promotions/${data.form?.id}`)

      const promotions = cloneDeep(state.promotions.data)
      const index = findIndex(promotions, { id: data.form?.id })
      promotions.splice(index, 1)

      state.promotions = {
        ...state.promotions,
        data: promotions,
      }
      state.promotions.total--
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.DELETE.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.DELETE.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
}

export const actions = {
  async getPromotions(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_GLOBAL_PROMOTIONS(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  async getShopPromotions(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_SHOP_PROMOTIONS(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  async createPromotions(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.CREATE_PROMOTIONS(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  async editPromotions(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.EDIT_PROMOTIONS(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  async deletePromotions(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.DELETE_PROMOTIONS(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
}

export const getters = {
  // Add Logic Before Computed
  promotions(state) {
    // eslint-disable-line
    return state.promotions
  },
}

export const promotions = {
  state,
  mutations,
  actions,
  getters,
}
