import { cloneDeep, findIndex } from 'lodash'
import {
  REQUEST_GET,
  REQUEST_POST,
  REQUEST_PUT,
  REQUEST_DELETE,
} from './API_Request'
import { AlertMessage, errorMessage } from '@/plugins/common'
import { GLOBAL_MESSAGE } from '@/plugins/message'

export const state = {
  shops: {
    data: [],
    total: 0,
  },
  shopCategories: {
    data: [],
    total: 0,
  },
  shopTypes: {
    data: [],
    total: 0,
  },
}

export const mutations = {
  async GET_SHOPS(state, data) {
    if (data?.form?.isLoading !== false) data.toggleLoading()
    try {
      const res = await REQUEST_GET('/api/shops', data.form)
      state.shops.data = res?.data?.data || []
      state.shops.total = res?.data?.total || 0
      if (data?.form?.isLoading !== false) data.toggleLoading()
      return state
    } catch (e) {
      if (data?.form?.isLoading !== false) data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_SHOP_CATEGORIES(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET('/api/shop-categories', data.form)
      state.shopCategories.data = res?.data?.data || []
      state.shopCategories.total = res?.data?.total || 0
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_SHOP_TYPES(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET('/api/shop-types', data.form)
      state.shopTypes.data = res?.data?.data || []
      state.shopTypes.total = res?.data?.total || 0
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async CREATE_SHOPS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_POST('/api/shops', data.form)
      state.shops.data.push(res.data)
      state.shops.total++
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.CREATE.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.CREATE.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
  async EDIT_SHOPS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_PUT(`/api/shops/${data.form?.id}`, data.form)
      const shops = cloneDeep(state.shops.data)
      const index = findIndex(shops, { id: res?.data?.id })

      shops[index] = res.data
      state.shops = {
        ...state.shops,
        data: shops,
      }
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.EDIT.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.EDIT.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
  async DELETE_SHOPS(state, data) {
    data.toggleLoading()
    try {
      await REQUEST_DELETE(`/api/shops/${data.form?.id}`)

      const shops = cloneDeep(state.shops.data)
      const index = findIndex(shops, { id: data.form?.id })
      shops.splice(index, 1)

      state.shops = {
        ...state.shops,
        data: shops,
      }
      state.shops.total--
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.DELETE.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.DELETE.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
}

export const actions = {
  async getShops(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_SHOPS(state, { toggleLoading, form, toggleAlert })
  },
  async getShopCategories(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_SHOP_CATEGORIES(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  async getShopTypes(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_SHOP_TYPES(state, { toggleLoading, form, toggleAlert })
  },
  async createShops(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.CREATE_SHOPS(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  async editShops(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.EDIT_SHOPS(state, { toggleLoading, form, toggleAlert })
  },
  async deleteShops(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.DELETE_SHOPS(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
}

export const getters = {
  // Add Logic Before Computed
  shops(state) {
    // eslint-disable-line
    return state.shops
  },
  shopCategories(state) {
    // eslint-disable-line
    return state.shopCategories
  },
  shopTypes(state) {
    // eslint-disable-line
    return state.shopTypes
  },
}

export const shops = {
  state,
  mutations,
  actions,
  getters,
}
