import { REQUEST_GET, REQUEST_PUT,REQUEST_POST } from './API_Request'
import { AlertMessage, errorMessage } from '@/plugins/common'
import { GLOBAL_MESSAGE,SEND_LINK_MESSAGE } from '@/plugins/message'

export const state = {
  customerProfile: undefined,
  history: {
    data: [],
    total: 0,
  },
}

export const mutations = {
  async GET_ME(state,data){
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(`/api/me`)
      state.customerProfile = res?.data || undefined
      state.receipts.data = []
      state.receipts.total = 0
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },

  async GET_PROFILE(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(`/api/users/${data.id}`)
      state.customerProfile = res?.data || undefined
      state.receipts.data = []
      state.receipts.total = 0
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },

  async GET_HISTORY(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(`/api/users/${data?.form?.params?.id}/points`, data?.form?.query)
      state.history.data = res?.data?.data || []
      state.history.total = res?.data?.total || 0
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },

  async UPDATE_PROFILE(state, data) {
    data.toggleLoading()
    try {
      const { id, ...form } = data.form
      await REQUEST_PUT(`/api/users/customers/${id}`, form)

      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.UPDATE.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.UPDATE.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },

  async UPDATE_PROFILE_REFCODE(state, data) {
    data.toggleLoading()
    try {
      await REQUEST_PUT(`/api/users/refcode`, data.form)

      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.UPDATE.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.UPDATE.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
  async SEND_CUSTOMER_LINK(state, data) {
    data.toggleLoading()
    try {
      await REQUEST_POST(`/api/users/customers/${data.id}/send-link`)
  
      data.toggleLoading()
  
      // Alert
      const alert = new AlertMessage(
        SEND_LINK_MESSAGE.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
  
      // Alert
      const alert = new AlertMessage(
        SEND_LINK_MESSAGE.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
}

export const actions = {
  async getMyProfile(context) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_ME(state, { toggleLoading, toggleAlert })
  },

  async getCustomerProfile(context, id) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_PROFILE(state, { toggleLoading, id, toggleAlert })
  },

  async getCustomerHistory(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_HISTORY(state, { toggleLoading, form, toggleAlert })
  },

  async updateCustomerProfile(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.UPDATE_PROFILE(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },

  async updateCustomerProfileByRefcode(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.UPDATE_PROFILE_REFCODE(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  async sendCustomerLink(context, id) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.SEND_CUSTOMER_LINK(state, {
      toggleLoading,
      id,
      toggleAlert,
    })
  },
}

export const getters = {
  customerProfile(state) {
    return state.customerProfile
  },
  customerHistory(state) {
    return state.history
  },
}

export const customer = {
  state,
  mutations,
  actions,
  getters,
}
