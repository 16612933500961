import { cloneDeep, findIndex, size } from 'lodash'
import { REQUEST_DELETE, REQUEST_GET, REQUEST_PUT } from './API_Request'
import { AlertMessage, errorMessage } from '@/plugins/common'
import { GLOBAL_MESSAGE } from '@/plugins/message'

export const state = {
  points: {
    data: [],
    total: 0,
  },
  shopPoints: {
    data: [],
    total: 0,
  },
  defaultPoint: {
    data: [],
    total: 0,
  },
}

export const mutations = {
  async GET_POINTS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET('/api/amount-per-points', data.form)
      state.points.data = res?.data?.data || []
      state.points.total = res?.data?.total || 0
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_SHOP_POINTS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(
        `/api/shops/${data.form.shopId}/amount-per-point`
      )
      state.shopPoints.data = res?.data?.data || []
      state.shopPoints.total = res?.data?.total || 0
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_DEFAULT_POINTS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET('/api/configs/amount-per-point', data.form)
      let result = []
      if (size(res?.data) > 1) {
        result = res?.data
      } else {
        result = [res?.data]
      }
      state.defaultPoint.data = result
      state.defaultPoint.total = size(result) || 0
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async UPDATE_POINTS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_PUT(
        `/api/shops/${data.form.shopId}/amount-per-point`,
        data.form
      )
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.EDIT.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return res
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async UPDATE_DEFAULT_POINTS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_PUT(`/api/configs/amount-per-point`, data.form)
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.EDIT.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return res
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async DELETE_POINTS(state, data) {
    data.toggleLoading()
    try {
      await REQUEST_DELETE(`/api/shops/${data.form.shopId}/amount-per-point`)
      const points = cloneDeep(state.points.data)
      const index = findIndex(points, { shopId: data.form?.shopId })
      points.splice(index, 1)

      state.points = {
        ...state.points,
        data: points,
      }
      state.points.total--
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.DELETE.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
}

export const actions = {
  async getPoints(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_POINTS(state, { toggleLoading, form, toggleAlert })
  },
  async getShopPoints(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_SHOP_POINTS(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  async getDefaultPoints(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_DEFAULT_POINTS(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  async updatePoints(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.UPDATE_POINTS(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  async updateDefaultPoints(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.UPDATE_DEFAULT_POINTS(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  async deletePoints(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.DELETE_POINTS(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
}

export const getters = {
  // Add Logic Before Computed
  points(state) {
    // eslint-disable-line
    return state.points
  },
  shopPoints(state) {
    // eslint-disable-line
    return state.shopPoints
  },
  defaultPoint(state) {
    // eslint-disable-line
    return state.defaultPoint
  },
}

export const points = {
  state,
  mutations,
  actions,
  getters,
}
