import { REQUEST_POST } from './API_Request'
import { AlertMessage, errorMessage } from '@/plugins/common'
import { GLOBAL_MESSAGE } from '@/plugins/message'

export const state = {
  receipts: {
    data: [],
    total: 0,
  },
}

export const mutations = {
  async ADD_RECEIPT(state, data) {
    const duplicated = state.receipts.data.some(
      (row) =>
        row.shop.id == data.form.shopId && row.receiptNo == data.form.receiptNo
    )
    if (duplicated) {
      const alert = new AlertMessage('เลขบิลถูกใช้ในระบบแล้ว', 'error', 'error')
      data.toggleAlert(alert)
      return
    }

    data.toggleLoading()
    try {
      const res = await REQUEST_POST('/api/points/calculate', data.form)
      data.toggleLoading()
      if (!res.data.CodeValid) {
        // Alert
        const alert = new AlertMessage(
          'เลขบิลถูกใช้ในระบบแล้ว',
          'error',
          'error'
        )
        data.toggleAlert(alert)
        return
      }

      state.receipts.data.push({
        receiptNo: data.form.receiptNo,
        shop: data.form.shopName,
        total: res.data.Amount,
        totalPoint: res.data.Point,
      })
      state.receipts.total++

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.CREATE.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.CREATE.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },

  async SAVE_RECEIPT(state, data) {
    data.toggleLoading()
    try {
      const submitForm = {
        userId: data.form.userId,
        receipts: state.receipts.data.map((r) => {
          return {
            shopId: r.shop.id,
            receiptNo: r.receiptNo,
            amount: r.total,
          }
        }),
      }

      await REQUEST_POST('/api/receipts', submitForm)

      state.receipts.data = []
      state.receipts.total = 0
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.CREATE.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.CREATE.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
  async CLEAR_RECEIPT(state) {
    state.receipts.data = []
    state.receipts.total = 0
    return state
  },
}

export const actions = {
  async addReceipt(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.ADD_RECEIPT(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  async saveReceipt(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.SAVE_RECEIPT(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  clearReceipt() {
    return mutations.CLEAR_RECEIPT(state)
  },
}

export const getters = {
  receipts(state) {
    return state.receipts
  },
}

export const receipts = {
  state,
  mutations,
  actions,
  getters,
}
