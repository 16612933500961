import { cloneDeep, findIndex } from 'lodash'
import {
  REQUEST_GET,
  REQUEST_POST,
  REQUEST_PUT,
  REQUEST_DELETE,
} from './API_Request'
import { AlertMessage, jsonToForm, errorMessage } from '@/plugins/common'
import { GLOBAL_MESSAGE } from '@/plugins/message'

export const state = {
  vouchers: {
    data: [],
    total: 0,
  },
  voucherCode: null,
  voucherDetail: null,
}

export const mutations = {
  async GET_VOUCHERS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET('/api/vouchers', data.form)
      state.vouchers.data = res?.data?.data || []
      state.vouchers.total = res?.data?.total || 0
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_VOUCHER_DETAIL(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(`/api/vouchers/${data.form?.voucherId}`)
      state.voucherDetail = res?.data
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async SEARCH_VOUCHER(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(
        `/api/voucher-codes/search/${data.form?.code}`
      )
      data.toggleLoading()
      return res
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async GET_VOUCHER_CODE(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(
        `/api/vouchers/${data.form?.voucherId}/codes`,
        data.form
      )
      state.voucherCode = res?.data
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async CREATE_VOUCHERS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_POST('/api/vouchers', jsonToForm(data.form))
      state.vouchers.data.push(res.data)
      state.vouchers.total++
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.CREATE.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.CREATE.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
  async EDIT_VOUCHERS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_PUT(
        `/api/vouchers/${data.form?.id}`,
        jsonToForm(data.form)
      )
      const vouchers = cloneDeep(state.vouchers.data)
      const index = findIndex(vouchers, { id: res?.data?.id })

      vouchers[index] = res.data
      state.vouchers = {
        ...state.vouchers,
        data: vouchers,
      }
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.EDIT.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.EDIT.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
  async DELETE_VOUCHERS(state, data) {
    data.toggleLoading()
    try {
      await REQUEST_DELETE(`/api/vouchers/${data.form?.id}`)

      const vouchers = cloneDeep(state.vouchers.data)
      const index = findIndex(vouchers, { id: data.form?.id })
      vouchers.splice(index, 1)

      state.vouchers = {
        ...state.vouchers,
        data: vouchers,
      }
      state.vouchers.total--
      data.toggleLoading()

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.DELETE.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.DELETE.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
  async REDEEM_VOUCHERS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_POST(
        `/api/users/customers/${data?.form?.customerId}/voucher-codes/redeem`,
        { voucherCodeIds: data?.form?.voucherCodeIds }
      )
      data.toggleLoading()
      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.CREATE.SUCCESS,
        'ok',
        'success'
      )
      data.toggleAlert(alert)
      return res
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.CREATE.ERROR,
        'error',
        'error'
      )
      data.toggleAlert(alert)
      return error
    }
  },
}

export const actions = {
  async getVouchers(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_VOUCHERS(state, { toggleLoading, form, toggleAlert })
  },
  async getVoucherDetail(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_VOUCHER_DETAIL(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  async searchVoucher(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.SEARCH_VOUCHER(state, { toggleLoading, form, toggleAlert })
  },
  async getVoucherCode(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_VOUCHER_CODE(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  async createVouchers(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.CREATE_VOUCHERS(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  async editVouchers(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.EDIT_VOUCHERS(state, { toggleLoading, form, toggleAlert })
  },
  async deleteVouchers(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.DELETE_VOUCHERS(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  async redeemVouchers(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.REDEEM_VOUCHERS(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
}

export const getters = {
  // Add Logic Before Computed
  vouchers(state) {
    // eslint-disable-line
    return state.vouchers
  },
  voucherDetail(state) {
    // eslint-disable-line
    return state.voucherDetail
  },
  voucherCode(state) {
    // eslint-disable-line
    return state.voucherCode
  },
}

export const vouchers = {
  state,
  mutations,
  actions,
  getters,
}
