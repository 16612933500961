import { mapActions, mapGetters } from 'vuex'

//Global computed and methods
export default {
  //Store getters
  computed: mapGetters([
    'isLoading',
    'alert',
    'auth',
    'defaultPoint',
    'points',
    'promotions',
    'shops',
    'shopCategories',
    'shopPoints',
    'shopTypes',
    'vouchers',
    'voucherDetail',
    'voucherCode',
    'users',
    'customerProfile',
    'customerHistory',
    'receipts',
  ]),
  //Store actions
  methods: {
    ...mapActions([
      'toggleLoading',
      'toggleAlertMessage',
      'appAccess',
      'login',
      'authWithRefCode',
      'logout',
      'forgotPassword',
      'resetPassword',
      'getPoints',
      'updatePoints',
      'deletePoints',
      'getDefaultPoints',
      'updateDefaultPoints',
      'getPromotions',
      'getShopPromotions',
      'createPromotions',
      'editPromotions',
      'deletePromotions',
      'getShops',
      'getShopCategories',
      'getShopPoints',
      'getShopTypes',
      'createShops',
      'editShops',
      'deleteShops',
      'getVouchers',
      'getVoucherDetail',
      'searchVoucher',
      'getVoucherCode',
      'createVouchers',
      'editVouchers',
      'deleteVouchers',
      'redeemVouchers',
      'getUsers',
      'exportReceive',
      'exportDeduct',
      'exportCustomer',
      'getDashboard',
      'exportDashboard',
      'getMyProfile',
      'getCustomerProfile',
      'getCustomerHistory',
      'updateCustomerProfile',
      'updateCustomerProfileByRefcode',
      'sendCustomerLink',
      'addReceipt',
      'saveReceipt',
      'clearReceipt',
      'acceptUserConsent',
      'checkUserConsent',
      'getUserConsent',
      'checkByUserMktConsent',
      'getMktConsent',
    ]),
  },
}
